// Contextual backgrounds
@mixin bg-variant($parent, $color, $ignore-warning: true) {
    #{$parent} {
        background-color: black !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background-color: white !important;
        }
    }
}

@mixin bg-gradient-variant($parent, $color) {
    #{$parent} {
        // background: linear-gradient(87deg, $color 0, adjust-hue($color, 58%) 100%) !important;
        background: ( #60d6e4)  !important;
    }
}

@mixin bg-translucent-variant($parent, $color) {
    #{$parent} {
        background: ( #60d6e4)  !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background: ( #60d6e4)  !important;
        }
    }
}
